import { useSpring, animated } from 'react-spring'
import { useMediaQuery } from '@kaliber/use-media-query'
import { ImageCover } from '/features/buildingBlocks/Image'
import { VideoLarge } from '/features/buildingBlocks/Video'

import mediaStyles from '/cssGlobal/media.css'
import styles from './VideoHeroGrid.css'

export function VideoHeroGrid({ video, videoHeroGridImages, layoutClassName }) {
  const isMd = useMediaQuery(mediaStyles.viewportMd) ?? false

  const leftGridImageSpring = useSpring({
    from: { x: '-50%', y: isMd ? '50%' : '-10%' },
    to: { x: '-70%', y: isMd ? '30%' : '-30%' },
  })

  const rightGridImageSpring = useSpring({
    from: { x: '50%', y: '50%' },
    to: { x: '70%', y: '30%' },
  })

  return (
    <div className={cx(styles.component, layoutClassName)}>
      {videoHeroGridImages?.length > 0 && (
        <animated.div style={leftGridImageSpring} className={styles.imageLeft}>
          <ImageCover layoutClassName={styles.imageLeftLayout} image={videoHeroGridImages[0]} aspectRatio={3 / 4} />
        </animated.div>
      )}
      <div className={styles.video}>
        <VideoLarge
          url={video.src}
          title={video.title}
          poster={video.poster}
          layoutClassName={styles.videoLayout}
        />
      </div>
      {videoHeroGridImages?.length > 1 && (
        <animated.div style={rightGridImageSpring}  className={styles.imageRight}>
          <ImageCover layoutClassName={styles.imageRightLayout} image={videoHeroGridImages[1]} aspectRatio={3 / 4} />
        </animated.div>
      )}
    </div>
  )
}
