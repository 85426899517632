import styles from './Heading.css'

export function HeadingXs({ title, h, layoutClassName = undefined }) {
  return <HeadingBase className={cx(styles.componentXs, layoutClassName)} {...{ h, title }} />
}

export function HeadingSm({ title, h, layoutClassName = undefined }) {
  return <HeadingBase className={cx(styles.componentSm, layoutClassName)} {...{ h, title }} />
}

export function HeadingMd({ title, h, layoutClassName = undefined }) {
  return <HeadingBase className={cx(styles.componentMd, layoutClassName)} {...{ h, title }} />
}

export function HeadingLg({ title, h, layoutClassName = undefined }) {
  return <HeadingBase className={cx(styles.componentLg, layoutClassName)} {...{ h, title }} />
}

export function HeadingXl({ title, h, layoutClassName = undefined }) {
  return <HeadingBase className={cx(styles.componentXl, layoutClassName)} {...{ h, title }} />
}

export function HeadingXxl({ title, h, layoutClassName = undefined }) {
  return <HeadingBase className={cx(styles.componentXxl, layoutClassName)} {...{ h, title }} />
}

function HeadingBase({ title, h, className }) {
  /** @type {any} */
  const HBase = `h${h}`

  return <HBase className={cx(styles.componentBase, className)}>{title}</HBase>
}
